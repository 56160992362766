<template>
  <div style="background-color: #fafafa; padding-top: 20px">
    <div style="height: auto;padding-bottom: 20px; background-color: white">
      <div class="center" style="padding: 30px 0; overflow: hidden">
        <div
          style="width: 320px; height: 100%; float: left; margin-right: 20px"
        >
          <div
            style="
              height: 280px;
              border: 1px solid rgba(220, 220, 220, 0.5);
              margin-bottom: 20px;
            "
          >
            <img
              :src="imgIndexUrl"
              style="width: 320px; height: 280px"
              alt=""
            />
          </div>
          <div style="height: 45px; line-height: 45px">
            <div
                @click="changeImageIndex(false)"
              style="
                float: left;
                font-weight: bold;
                color: rgba(220, 220, 220, 1);
                cursor: pointer;
              "
            ><</div>
            <div
              style="
                float: left;
                display: flex;
                width: 290px;
                margin: 0 auto;
                height: 45px;
                margin-right: 5px;
                margin-left: 5px;
                justify-content: flex-start;
              "
            >
              <img
                :class="{ select_img: imgIndex == index }"
                :src="item.url"
                alt=""
                @click="chooseImage(index)"
                v-for="(item, index) in goodsImage"
                :key="index"
                style="
                  border: 1px solid rgba(220, 220, 220, 0.5);
                  width: 45px;
                  height: 45px;
                  margin-right: 10px;
                "
              />
            </div>
            <div
              @click="changeImageIndex(true)"
              style="
                float: right;
                font-weight: bold;
                color: rgba(220, 220, 220, 1);
                cursor: pointer;
              "
            >
              >
            </div>
          </div>
        </div>
        <div style="float: left; margin-right: 20px; width: 625px">
          <div style="height: 110px">
            <div style="width: 430px; float: left">
              <div>
                <p style="font-size: 20px; color: black">{{ patent.ti }}</p>
              </div>
              <div style="margin-top: 10px">
                <p style="font-size: 14px">{{ patent.ti }}</p>
              </div>
            </div>
          </div>
          <div
            style="
              height: 60px;
              background-color: rgb(250, 250, 250);
              padding: 0 10px;
              line-height: 60px;
            "
          >
            <div style="float: left">
              交易价格：<span style="color: rgb(255, 82, 0); font-size: 20px"
                >￥{{ goods.JG ? goods.JG + '万' : '面议' }}</span
              >
            </div>
            <div style="float: right">
              交易方式：<span style="color: #1890ff">开放许可</span>
            </div>
          </div>
          <div style="margin-top: 20px; margin-bottom: 10px">
            <span class="sqz_span" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="patent.an">支付方式：{{ getGoodsPayType(goods.ZFFS) }}</span>
            <span class="sqz_span" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="patent.pa">许可期限：{{ goods.XKQX }}年</span>
          </div>
          <div style="margin-top: 20px; margin-bottom: 10px">
            <span class="sqz_span" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="patent.an">申请号：{{ patent.an }}</span>
            <span class="sqz_span" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="patent.pa">权利人：{{ patent.pa }}</span>
            <span class="sqz_span" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="patent.type">专利类型：{{ getPatentType(patent.patType) }}</span>
          </div>
          <div
            style="
              margin-bottom: 20px;
              padding-bottom: 20px;
              border-bottom: 1px solid rgba(220, 220, 220, 1);
            "
          >
            <span class="sqz_span" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="goods.LXR">联系人：{{ goods.LXR }}</span>
            <span class="sqz_span" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="goods.LXDH">联系电话：{{ goods.LXDH }}</span>
            <span class="sqz_span" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="goods.LXYX">联系邮箱：{{ goods.LXYX }}</span>
          </div>
        </div>
        <div
          v-if="0"
          style="
          height: 340px;
            width: 210px;
            background-color: rgb(242, 242, 242);
            float: left;
            border-radius: 5px;
            padding: 20px 10px;
          "
        >
          <div>
            <span style="font-size: 14px; color: black">{{ shop.name }}</span>
          </div>
          <div style="height: 150px;display: flex;align-items: center;justify-content: center">
            <Avatar icon="ios-person" size="70" style="margin: 0 auto" :src="shop.imageUrl"/>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <div
      class="center bottom"
      style="
        background-color: white;
        margin-top: 20px;
        border: 1px solid rgb(220, 220, 220);
        margin-bottom: 20px;
      "
    >
      <span class="bottom_select">
        商品详情
      </span>
      <div style="min-height: 500px;border-top: 1px solid rgb(220, 220, 220);border-bottom: 1px solid rgb(220, 220, 220);" class="comments">
        <div style="padding: 35px">
          <div class="detail-box">
            <h2>
              {{patent.pa}}
            </h2>
            <p>
              {{patent.ti}}
            </p>
            <table>
              <tbody>
                  <tr>
                      <td>
                        专利（申请号）：
                        {{patent.an}}
                      </td>
                      <td>
                        申请人：
                        {{patent.pa}}
                      </td>
                      <td>IPC分类号：
                        {{patent.pic}}
                      </td>
                  </tr>
                  <tr>
                      <td>专利类型:
                          {{getPatentType(patent.patType)}}
                      </td>
                      <td>公开号：
                          {{patent.pnm}}
                      </td>
                      <td>保护年限：
                          
                      </td>
                  </tr>
                  <tr>
                      <td>代理机构：
                          {{patent.agc}}
                      </td>
                      <td>代理人：
                         {{patent.agt}}
                      </td>
                      <td>公开日：
                          {{patent.pd}}
                      </td>
                  </tr>
                  <tr>
                      <td colspan="3">发明设计人：
                        {{patent.pin}}
                      </td>
                  </tr>
              </tbody>
            </table>
            <h3><strong>摘要</strong></h3>
            <p v-html="patent.ab" style="text-indent: 30px;">
            </p>
            <h3><strong>主权项</strong></h3>
            <p v-html="patent.clm" style="text-indent: 30px;">
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryLisenseDetail,
  queryPatent
} from "@/plugins/api/outerApi";
import { getLocalToken } from "@/plugins/tools/storage";
import {
  isCorrectPassword,
  isPhoneNumber,
  successToast,
  warnToast,
} from "@/plugins/tools/util";
import {errorToast} from "../plugins/tools/util";

export default {
  name: "PatentDetail",
  data() {
    return {
      commentsIndex: 1,
      imgIndex: 0,
      imgIndexUrl: "",
      bottomIndex: 0,
      bottomList: ["商品详情", "服务详情"], // ["商品详情", "服务详情", "商品咨询", "价值评估报告"],
      valueDisabled: 2,
      ans: '',
      goodsImage: [],
      patent: {},
      goods: {},
      textareaVal: "",
      commentList: [],
    };
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to, from) {
        this.ans = to.query.ans;
        this.getGoodDetail();
      }
    },
  },
  methods: {
    chooseImage(index){
      this.imgIndex = index;
      this.imgIndexUrl = this.goodsImage[this.imgIndex].url
    },
    getPatentType(type) {
      if (type == 1) {
        return '发明专利'
      } else if(type == 2) {
        return '外观设计'
      } else {
        return '实用新型'
      }
    },
    getGoodsPayType(type) {
      return ['免费', '入门费和提成费相结合', '一次总付', '总付额内分期付款', '其他明确合理的使用费标准'][type] || '--'
    },
    getPatentTypeImg(type) {
      if (type == 1) {
        return 'https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/famingzhuanli.jpg'
      } else if(type == 2) {
        return 'https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/shiyongxinxing.jpg'
      } else {
        return 'https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/waiguanzhuanli.jpg'
      }
    },
    //商品详情
    getGoodDetail() {
      const ans = this.ans.replace(/ZL/, 'CN')
      queryPatent({ans, pageSize: 10, pageNum: 1 }).then(res => {
        this.patent = res.result.list[0]
        //this.goodsImage = [{ url: this.patent.abPicPath || '' }]
        //this.imgIndexUrl = this.patent.abPicPath || ''
        const mainImg = this.getPatentTypeImg(this.patent.patType)
        this.goodsImage = [{ url: mainImg }]
        this.imgIndexUrl = mainImg
      })
      queryLisenseDetail({exp: this.ans}).then(res => {
        if (res.code === 200) {
          const result = JSON.parse(res.data || '{}').result
          this.goods = (result.data || [])[0]
        }
      })
    },
    chooseComments(index) {
      this.commentsIndex = index;
    },
    changeImageIndex(flag) {
      let max = this.goodsImage.length - 1;
      if (flag === true) {
        if (this.imgIndex < max) {
          this.imgIndex += 1;
        } else {
          this.imgIndex = 0;
        }
      }
      if (flag === false) {
        if (this.imgIndex !== 0) {
          this.imgIndex -= 1;
        } else {
          this.imgIndex = max;
        }
      }
      this.imgIndexUrl = this.goodsImage[this.imgIndex].url;
    }
  },
};
</script>

<style scoped lang="scss">
/deep/ .ivu-rate-star {
  margin-right: 2px;
}

.bottom_select {
  border-top: 1px solid #1890FF !important;
  color: #1890FF!important;
}

.comments_select {
  color: black;
}

.comments > span {
  display: inline-block;
  margin-left: 40px;
  line-height: 50px;
  font-size: 14px;
  cursor: pointer;
}

.bottom > span {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  border: 1px solid rgb(220, 220, 220);
  width: 140px;
  height: 40px;
  line-height: 40px;
}

.sqz_span {
  display: inline-block;
  width: 190px;
  margin-right: 10px;
}

.san_jiao:after {
  content: "";
  position: absolute;
  left: -10px;
  top: 10px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 10px solid #1890FF;
}

.si_hang_sheng {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.san_jiao:before {
  content: "";
  position: absolute;
  left: -8px;
  top: 10px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 10px solid rgba(24,144,255,0.1);
  z-index: 100;
}

.select_img {
  border: 1px solid #1890ff !important;
}

.center {
  width: 1200px;
  margin: 0 auto;
}

.html-box > img {
  display: block;
  margin: 0 auto;
  max-width: 100% !important;
  height: auto !important;
}

.html-box > p {
  max-width: 100%;
  word-break: break-all;
  font-size: 15px !important;
}

.html-box > span {
  max-width: 100%;
  word-break: break-all;
  font-size: 15px !important;
}

.html-box > table {
  width: 100%;
}
</style>
<style>
textarea.ivu-input {
  height: 120px !important;
}
</style>
